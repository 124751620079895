const FashionInfo = () => {
    return (
        <main id='tech-design-work ' className='wrapper'>
            <h2>
                What is a Technical Apparel Designer?
            </h2>
            <h3>
                <em>Well</em>, there is a surprising amount of overlap with software development...
            </h3>
            <p>
                Before I began my coding career, I spent half a decade working in the fashion industry as a Technical Apparel Designer.
            </p>
            <h3>Job Purpose & Goal:</h3>
            <p>
                My main focus in this role was to execute the design team's vision for each season's collection while protecting and enhancing best-in-class fit & function (read: user experience).
            </p>
            <p>
                My work was iterative, and involved weekly research to identify user pain points: <em>Is it warm enough? Is the pocket in an intuitive place? Can you sit down comfortably? Does it fit as expected?</em>
            </p>
            <p>
                From there, I would research, prototype, and test solutions to these user-focused problems before sending the best solution to stores (read: pushing to production).
            </p>
            <p>
                Throughout the development process, I analyzed user-submitted data and led focus groups to guide product improvements. I worked within tight timelines & budget constraints, managed cross-functional team members' expectations, and tracked product development using the industry's version control equivalent.
            </p>
            <h3>It's All Collaboration:</h3>
            <p>
                In this position, I collaborated closely with internal design, sourcing, and QA teams, as well as third-party factories, to develop over 60 products per quarter.
            </p>
            <p>
                The product I worked on was notoriously complex, and as a product expert, I led training efforts for new team members to ensure they quickly got up to speed with such a complicated product. I also developed and implemented standard operating procedures (SOPs) and best practices that streamlined workflows and improved efficiency. I provided ongoing support to ensure adherence to these standards, which contributed to improved team performance and consistency across projects.
            </p>
            <p>
                Additionally, my role included presenting my ideas and work to both my cross-functional peers and the brand's leadership team. To facilitate this, I led weekly collaborative working sessions with my peers to align on each style. I also summarized and presented high-level key takeaways to the company's leadership team for their buy-in prior to finalizing products and customer research.
            </p>
            <h3>Outcome:</h3>
            <p>
                In this role, user experience was quantified by True To Size (TTS) ratings calculated from online reviews. I improved the user experience by 9%, achieving an average of 94% TTS.
            </p>
        </main>
    );
}

export default FashionInfo;